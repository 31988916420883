import { FullUserResponse } from "soldat2-gatherbot-common/api/ratings"
import { useEffect, useState } from "react"
import { fetchUser } from "../util/api"
import _ from "lodash"

export type AddUserToCache = (playfabId: string, playlistCode: string, numGames: number) => void

export interface UserCache {
  [playlistCode: string]: { [playfabId: string]: FullUserResponse }
}

export const getUserFromCache = (
  userCache: UserCache,
  playfabId: string,
  playlistCode: string
): FullUserResponse | undefined => {
  if (playlistCode in userCache) {
    return userCache[playlistCode][playfabId]
  }

  return undefined
}

export type PlayerCacheEntry = {
  playfabId: string
  playlistCode: string
  gamesLimit: number
}

export type CacheState = {
  playerEntries: PlayerCacheEntry[]
  userCache: UserCache
}

export const useUserCache = () => {
  const [cacheState, setCacheState] = useState<CacheState>({ playerEntries: [], userCache: {} })
  // const [playfabIdPlaylistTriples, setPlayfabIdPlaylistTriples] = useState<PlayerCacheEntry[]>([])
  // const [userCache, setUserCache] = useState<UserCache>({})

  const addUserToCache: AddUserToCache = (
    playfabId: string,
    playlistCode: string,
    gamesLimit: number
  ): void => {
    setCacheState((oldState: CacheState) => {
      const oldEntries = oldState.playerEntries

      const existingEntry = _.find(oldEntries, (entry: PlayerCacheEntry) => {
        return playfabId === entry.playfabId && playlistCode === entry.playlistCode
      })

      if (existingEntry === undefined) {
        const newEntries = [...oldEntries, { playfabId, playlistCode, gamesLimit }]
        return {
          playerEntries: newEntries,
          userCache: oldState.userCache,
        }
      }

      if (existingEntry.gamesLimit !== gamesLimit) {
        console.log(`Changing limit for ${playfabId} to ${gamesLimit}`)

        const newEntries = [...oldEntries]
        _.remove(newEntries, (entry: PlayerCacheEntry) => {
          return playfabId === entry.playfabId && playlistCode === entry.playlistCode
        })
        newEntries.push({ playfabId, playlistCode, gamesLimit })

        const newCache = { ...oldState.userCache }
        delete newCache[playlistCode][playfabId]

        return {
          playerEntries: newEntries,
          userCache: newCache,
        }
      }

      return oldState
    })
  }

  useEffect(() => {
    const updateCache = async () => {
      const newUserCache = { ...cacheState.userCache }

      for (let { playfabId, playlistCode, gamesLimit } of cacheState.playerEntries) {
        if (!_.includes(_.keys(newUserCache), playlistCode)) {
          newUserCache[playlistCode] = {}
        }

        if (!_.includes(_.keys(newUserCache[playlistCode]), playfabId)) {
          const user = await fetchUser(playfabId, playlistCode, gamesLimit)
          console.log(
            `Fetched user ${user.displayName} for playlist ${playlistCode} with games limit ${gamesLimit}`
          )

          newUserCache[playlistCode][playfabId] = user
        }
      }

      setCacheState((oldState) => ({
        userCache: newUserCache,
        playerEntries: oldState.playerEntries,
      }))
    }

    updateCache().catch((e) => console.error(e))
  }, [cacheState.playerEntries])

  return { userCache: cacheState.userCache, addUserToCache }
}
