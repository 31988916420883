import React, { SyntheticEvent, useState } from "react"
import { Input, Popup } from "semantic-ui-react"

export const NumericInput = ({
  value,
  onSubmit,
  width,
}: {
  value: number
  onSubmit: (newValue: number) => void
  width: number
}) => {
  const [inputString, setInputString] = useState(value.toString())

  const valueChanged = value.toString() != inputString

  return (
    <Popup
      content={"Enter to submit"}
      open={valueChanged}
      hoverable={false}
      eventsEnabled={false}
      on={"focus"}
      trigger={
        <Input
          value={inputString}
          style={{
            width: `${width}px`,
            marginLeft: "5px",
            marginRight: "5px",
          }}
          onChange={(e) => {
            setInputString(e.target.value)
          }}
          onBlur={() => {
            const newValue = parseInt(inputString)
            if (!isNaN(newValue)) {
              onSubmit(newValue)
            }
          }}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === "Enter") {
              const newValue = parseInt(inputString)
              if (!isNaN(newValue)) {
                onSubmit(newValue)
              }
            }
          }}
        />
      }
    />
  )
}
