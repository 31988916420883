import React from "react"
import { RatingsContextProvider } from "./ratings"
import { TiersContextProvider } from "./tiers"
import { GameCacheContextProvider } from "../hooks/useGameCache"

export const GlobalApiDataContextProvider = ({
  children,
  playlistCode,
}: {
  children: React.ReactNode
  playlistCode: string
}) => {
  return (
    <RatingsContextProvider playlistCode={playlistCode}>
      <TiersContextProvider>
        <GameCacheContextProvider>{children}</GameCacheContextProvider>
      </TiersContextProvider>
    </RatingsContextProvider>
  )
}
