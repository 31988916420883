import React, { MouseEvent, useEffect, useState } from "react"
import { Search, SearchProps, StrictSearchResultProps } from "semantic-ui-react"
import _ from "lodash"
import { Playlist } from "soldat2-gatherbot-common/game/playlist"
import { GameModeSettings } from "soldat2-gatherbot-common/game/gamemode/settings"

export const PlaylistCodePicker = ({
  playlists,
  playlistCode,
  setPlaylistCode,
}: {
  playlists: Playlist<GameModeSettings>[]
  playlistCode: string
  setPlaylistCode: (playlistCode: string) => void
}) => {
  const [searchResults, setSearchResults] = useState<StrictSearchResultProps[]>()
  const [searchValue, setSearchValue] = useState<string>("")
  const [searchOpen, setSearchOpen] = useState<boolean>(false)

  useEffect(() => {
    setSearchResults((oldSearchResults) => {
      return playlists.map((playlist) => ({
        title: playlist.code,
      }))
    })
  }, [playlists])

  const handleSearchChange = (e: MouseEvent, data: SearchProps) => {
    const value = data.value
    setSearchValue(value || "")

    const re = new RegExp(_.escapeRegExp(value), "i") // Search substrings, ignore case
    const results = _.filter(playlists, (playlist) => re.test(playlist.code))

    setSearchResults(
      results.map((result) => {
        return {
          // This is used internally by React as a key in a list. Because multiple players
          // can have the same display name, using the display name as the title causes rendering
          // issues, as keys have to be unique. Therefore, we use playfabId as the title, but we
          // override the resultRenderer to display things as we want.
          title: result.code,
          // TODO: Add game mode emoji
          // image: "",
          // description: ``,
        }
      })
    )
  }
  const onResultSelect = (e: MouseEvent<HTMLDivElement>, playlistCode: string) => {
    setPlaylistCode(playlistCode)
    setSearchValue("")
    setSearchOpen(false)
  }

  return (
    <Search
      placeholder={playlistCode}
      onResultSelect={(e, data) => onResultSelect(e, data.result.title)}
      onSearchChange={handleSearchChange}
      results={searchResults}
      value={searchValue}
      open={searchOpen}
      onFocus={() => setSearchOpen(true)}
      onBlur={() => setSearchOpen(false)}
      onMouseDown={() => setSearchOpen(true)}
      resultRenderer={({ image, title, description }) => {
        return (
          <>
            {image && (
              <div key="image" className="image">
                <img src={image} alt={"Rank"} />
              </div>
            )}
            <div key="content" className="content">
              {title && <div className="title">{title}</div>}
            </div>
          </>
        )
      }}
    />
  )
}
