import {
  FinishedCaptureTheFlagRound,
  FinishedDeathmatchRound,
  FinishedDominationRound,
  FinishedFreeForAllRound,
  FinishedRound,
  FinishedTeamDeathmatchRound,
  FinishedTeamRound,
} from "../finished/round"
import { WithPlayerKillsAndDeaths } from "../player"
import { GameModeName } from "../gameMode"

export type OmitEvents<T> = Omit<T, "events">

export type ResolveFinishedRound<T extends FinishedRound> = WithPlayerKillsAndDeaths<OmitEvents<T>>
export type ResolvedFinishedRound = ResolveFinishedRound<FinishedRound>
export type ResolvedFinishedTeamRound = ResolveFinishedRound<FinishedTeamRound>
export type ResolvedFinishedFreeForAllRound = ResolveFinishedRound<FinishedFreeForAllRound>
export type ResolvedFinishedCaptureTheFlagRound = ResolveFinishedRound<FinishedCaptureTheFlagRound>
export type ResolvedFinishedTeamDeathmatchRound = ResolveFinishedRound<FinishedTeamDeathmatchRound>
export type ResolvedFinishedDominationRound = ResolveFinishedRound<FinishedDominationRound>
export type ResolvedFinishedDeathmatchRound = ResolveFinishedRound<FinishedDeathmatchRound>

export const getBlueScore = (
  gameMode: GameModeName,
  round: OmitEvents<FinishedTeamRound>
): number => {
  if (gameMode === GameModeName.CaptureTheFlag) {
    return (round as FinishedCaptureTheFlagRound).blueCaps
  } else if (gameMode === GameModeName.TeamDeathmatch) {
    return (round as FinishedTeamDeathmatchRound).blueKills
  } else if (gameMode === GameModeName.Domination) {
    return (round as FinishedDominationRound).blueCaps
  } else {
    throw new Error(`Invalid team game mode: ${gameMode}`)
  }
}
export const getRedScore = (
  gameMode: GameModeName,
  round: OmitEvents<FinishedTeamRound>
): number => {
  if (gameMode === GameModeName.CaptureTheFlag) {
    return (round as FinishedCaptureTheFlagRound).redCaps
  } else if (gameMode === GameModeName.TeamDeathmatch) {
    return (round as FinishedTeamDeathmatchRound).redKills
  } else if (gameMode === GameModeName.Domination) {
    return (round as FinishedDominationRound).redCaps
  } else {
    throw new Error(`Invalid team game mode: ${gameMode}`)
  }
}
