import { ResolvedFinishedGameForPlayer } from "soldat2-gatherbot-common/game/resolved/gameForPlayer"
import {
  isResolvedFinishedFreeForAllGame,
  isResolvedFinishedTeamGame,
  ResolvedFinishedGame,
} from "soldat2-gatherbot-common/game/resolved/game"
import { TeamGameDetails, TeamGamePopupForPlayer } from "./team"
import { DeathmatchGameDetails, DeathmatchGamePopupForPlayer } from "./freeForAll"
import { useGameCache } from "../../hooks/useGameCache"
import { useEffect, useState } from "react"

export const GamePopupForPlayer = ({
  game,
  interactive,
}: {
  game: ResolvedFinishedGameForPlayer
  interactive: boolean
}) => {
  if (isResolvedFinishedTeamGame(game)) {
    return <TeamGamePopupForPlayer game={game} interactive={interactive} />
  } else if (isResolvedFinishedFreeForAllGame(game)) {
    return <DeathmatchGamePopupForPlayer game={game} interactive={interactive} />
  } else {
    throw new Error("Unexpected game")
  }
}
export const GameDetails = ({ game, alpha }: { game: ResolvedFinishedGame; alpha: number }) => {
  const { getGame } = useGameCache()

  const [gameToDisplay, setGameToDisplay] = useState<ResolvedFinishedGame>(game)

  useEffect(() => {
    getGame(game.startTime).then((game) => {
      setGameToDisplay(game)
    })
  }, [])

  if (isResolvedFinishedTeamGame(gameToDisplay)) {
    return <TeamGameDetails game={gameToDisplay} alpha={alpha} />
  } else if (isResolvedFinishedFreeForAllGame(gameToDisplay)) {
    return <DeathmatchGameDetails game={gameToDisplay} alpha={alpha} />
  } else {
    throw new Error("Unexpected game")
  }
}
