import * as React from "react"
import { Grid, List } from "semantic-ui-react"
import { KillsAndDeathsPerPlayer, Player } from "soldat2-gatherbot-common/game/player"
import { formatKillsAndDeaths } from "soldat2-gatherbot-common/game/util/formatting"

export interface TeamInfo {
  teamName: string
  players: Player[]
  color: string
  winProbability?: number
  floated: "right" | "left"
}

interface Props {
  team: TeamInfo
  playerKillsAndDeaths: KillsAndDeathsPerPlayer
}

export const PlayersColumn = ({ team, playerKillsAndDeaths }: Props) => {
  return (
    <Grid.Column
      // width={6}
      floated={team.floated}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <List
        className={"player-list"}
        // divided
      >
        <List.Item
          style={{
            backgroundColor: team.color,
            padding: "5px",
          }}
        >
          <List.Content>
            <b>
              {team.teamName} Team
              <br />
              {team.winProbability !== undefined
                ? `(${(team.winProbability * 100).toFixed(1)}% chance to win)`
                : null}
            </b>
          </List.Content>
        </List.Item>
        {team.players.map((player, i) => {
          let contents

          if (player !== undefined) {
            const kd = playerKillsAndDeaths[player.playfabId]
            contents = (
              <span>
                <b>{player.displayName}</b>: {formatKillsAndDeaths(kd.kills, kd.deaths)}
              </span>
            )
          } else {
            contents = "Loading..."
          }

          return (
            <List.Item
              key={i}
              style={{
                backgroundColor: team.color,
                padding: "5px",
              }}
            >
              <List.Content>{contents}</List.Content>
            </List.Item>
          )
        })}
      </List>
    </Grid.Column>
  )
}
