export enum MatchResultForPlayer {
  VICTORY = "WIN",
  LOSS = "LOSS",
  TIE = "TIE",
}

export interface MatchResult {
  // Null if tie
  winner: string | null

  isTie: boolean
}
