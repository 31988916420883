import { Button, Dimmer, Form, Grid } from "semantic-ui-react"
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal"
import * as React from "react"
import { useContext, useEffect, useState } from "react"
import { RatingCard } from "../components/RatingCard"
import "./PlayerStatsModal.css"
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader"
import { FullUserResponse } from "soldat2-gatherbot-common/api/ratings"
import { TierChangesGraph } from "../graphs/TierChangesGraph"
import { useNavigate, useParams } from "react-router"
import { RatingsContext } from "../context/ratings"
import { TiersContext } from "../context/tiers"
import { AddUserToCache, getUserFromCache, UserCache } from "../hooks/useUserCache"
import { useNavigatePreservingSearchParams } from "../hooks/useNavigatePreservingSearchParams"
import { PlaylistCodePicker } from "../components/PlaylistCodePicker"
import { Playlist } from "soldat2-gatherbot-common/game/playlist"
import { GameModeSettings } from "soldat2-gatherbot-common/game/gamemode/settings"
import MetaTags from "react-meta-tags"

type Params = {
  playfabId: string
}

export const PlayerStatsModal = ({
  addUserToCache,
  userCache,
  playlists,
  playlistCode,
  setPlaylistCode,
}: {
  userCache: UserCache
  addUserToCache: AddUserToCache
  playlists: Playlist<GameModeSettings>[]
  playlistCode: string
  setPlaylistCode: (playlistCode: string) => void
}) => {
  const { playfabId } = useParams<Params>()
  const navigate = useNavigate()

  const { navigatePreservingSearchParams } = useNavigatePreservingSearchParams()

  const { ratings } = useContext(RatingsContext)
  const { tiers } = useContext(TiersContext)

  const [xAxisType, setXAxisType] = useState<"time" | "matches">("matches")
  const [numLastGames, setNumLastGames] = useState(10)

  let user: FullUserResponse | undefined = getUserFromCache(userCache, playfabId!, playlistCode)

  useEffect(() => {
    addUserToCache(playfabId!, playlistCode, numLastGames)
  }, [playfabId, playlistCode, numLastGames])

  const onClose = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigatePreservingSearchParams("/", { replace: true }) // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  }

  return (
    <Modal
      dimmer={"inverted"}
      open={true}
      onClose={onClose}
      size="large"
      style={{
        width: "95%",
      }}
    >
      <MetaTags>
        <title>Stats for {user !== undefined ? user.displayName : playfabId}</title>
        <meta name="description" content="this is a player" />
        <meta property="og:title" content="some title" />
        <meta property="og:image" content="http://localhost:3000/images/ranks/GOLD_I.png" />
      </MetaTags>
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>Stats for {user !== undefined ? user.displayName : playfabId}</Grid.Column>
            <Grid.Column width={"4"} floated={"right"}>
              <div style={{ fontSize: "0.8em" }}>
                <PlaylistCodePicker
                  playlists={playlists}
                  playlistCode={playlistCode}
                  setPlaylistCode={setPlaylistCode}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content scrolling className={"rating-modal"}>
        {user !== undefined ? (
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <RatingCard
                  interactive
                  numLastGames={numLastGames}
                  setNumLastGames={setNumLastGames}
                  user={user}
                />
              </Grid.Column>
              <Grid.Column>
                <h3>Rank Changes</h3>
                <Form>
                  <p>Click & drag to zoom. Double-click to zoom out fully.</p>
                  <Form.Group inline>
                    <label>X-Axis</label>
                    <Form.Radio
                      inline
                      label={"Time"}
                      value={"time"}
                      checked={xAxisType === "time"}
                      onChange={(e, { value }) => setXAxisType(value as "time" | "matches")}
                    />
                    <Form.Radio
                      inline
                      label={"Matches"}
                      value={"matches"}
                      checked={xAxisType === "matches"}
                      onChange={(e, { value }) => setXAxisType(value as "time" | "matches")}
                    />
                  </Form.Group>
                </Form>

                <TierChangesGraph
                  gamesPerPlayer={{ [user.playfabId]: user.sortedGames }}
                  xAxisType={xAxisType}
                  numGamesToDisplay={numLastGames}
                  showLegend={false}
                  figureHeight={550}
                  getGameNumber={(game) => game.gameNumberForPlayerInPlaylist}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Dimmer active inverted>
            <Loader inverted>Loading...</Loader>
          </Dimmer>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
