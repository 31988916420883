export enum SoldatTeam {
  BLUE = "Blue",
  RED = "Red",
}

export enum VotingTeam {
  BLUE = "Blue",
  RED = "Red",
  EVERYONE = "Everyone",
}

export interface NumberPerTeam {
  [teamId: string]: number
}

export interface ContainsTeam {
  team: string
}

export interface ValuePerTeam<T extends ContainsTeam[]> {
  [teamId: string]: T
}

export const votingTeamToTeamId = (team: VotingTeam): number => {
  if (team == VotingTeam.BLUE) {
    return 0
  } else if (team == VotingTeam.RED) {
    return 1
  } else if (team == VotingTeam.EVERYONE) {
    return -1
  } else {
    throw Error(`Got an unexpected voting team: ${team}`)
  }
}
export const soldatTeamToVotingTeam = (team: SoldatTeam): VotingTeam => {
  if (team == SoldatTeam.BLUE) {
    return VotingTeam.BLUE
  } else if (team == SoldatTeam.RED) {
    return VotingTeam.RED
  } else {
    throw Error(`Got an unexpected soldat team: ${team}`)
  }
}

export const getSoldatTeamById = (id: string): SoldatTeam => {
  return id === "0" ? SoldatTeam.BLUE : SoldatTeam.RED
}

export const soldatTeamToId = (team: SoldatTeam): number => {
  if (team == SoldatTeam.BLUE) {
    return 0
  } else if (team == SoldatTeam.RED) {
    return 1
  } else {
    throw Error(`Got an unexpected soldat team: ${team}`)
  }
}

export const getTeams = <T extends ContainsTeam>(players: T[]): ValuePerTeam<T[]> => ({
  [SoldatTeam.BLUE]: players.filter((player) => player.team === SoldatTeam.BLUE),
  [SoldatTeam.RED]: players.filter((player) => player.team === SoldatTeam.RED),
})
