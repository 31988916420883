import React, { useContext, useState } from "react"
import { Search, SearchProps, SearchResultProps, StrictSearchResultProps } from "semantic-ui-react"
import _ from "lodash"
import { getRankIconUrl } from "../util/ranks"
import { RatingsContext } from "../context/ratings"

export const PlayerSearchInput = ({
  onResultSelect,
}: {
  onResultSelect: (playfabId: string) => void
}) => {
  const { ratings } = useContext(RatingsContext)

  const [searchResults, setSearchResults] = useState<StrictSearchResultProps[]>([])
  const [searchValue, setSearchValue] = useState<string>("")

  const handleSearchChange = (e: React.MouseEvent, data: SearchProps) => {
    const value = data.value
    setSearchValue(value || "")

    const re = new RegExp(_.escapeRegExp(value), "i") // Search substrings, ignore case
    const results = _.filter(ratings, (rating) => re.test(rating.displayName))

    setSearchResults(
      results.map((result) => {
        return {
          // This is used internally by React as a key in a list. Because multiple players
          // can have the same display name, using the display name as the title causes rendering
          // issues, as keys have to be unique. Therefore, we use playfabId as the title, but we
          // override the resultRenderer to display things as we want.
          title: result.playfabId,
          displayName: result.displayName,
          playfabId: result.playfabId,
          image: getRankIconUrl(result.tier),
          description: `Rating: ${(result.mu - 3 * result.sigma).toFixed(2)}`,
        }
      })
    )
  }

  return (
    <Search
      placeholder={"Player name"}
      onResultSelect={(e, data) => onResultSelect(data.result.playfabId)}
      onSearchChange={handleSearchChange}
      results={searchResults}
      value={searchValue}
      resultRenderer={
        // Title actually means playfabId here, and it's not rendered, see above comment
        ({ image, title, displayName, description }) => {
          return (
            <>
              {image && (
                <div key="image" className="image">
                  <img src={image} alt={"Rank"} />
                </div>
              )}
              <div key="content" className="content">
                {displayName && <div className="title">{displayName}</div>}
                {description && <div className="description">{description}</div>}
              </div>
            </>
          )
        }
      }
    />
  )
}
