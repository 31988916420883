import { Container, Dimmer, Form, Header, Segment } from "semantic-ui-react"
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader"
import SemanticDatepicker from "react-semantic-ui-datepickers"
import moment from "moment/moment"
import * as React from "react"
import { useEffect, useState } from "react"
import { GamesStatsForDay } from "soldat2-gatherbot-common/api/games"
import { GathersGraph } from "../graphs/GathersGraph"
import { fetchGamesPerDay } from "../util/api"

export const GathersPage = ({}) => {
  const today = moment()
  const threeMonthsAgo = today.clone().subtract(3, "months")

  const [startDate, setStartDate] = useState<Date>(new Date(threeMonthsAgo.format("YYYY-MM-DD")))
  const [endDate, setEndDate] = useState<Date>(new Date(today.format("YYYY-MM-DD")))

  const [fetching, setFetching] = useState<boolean>(false)
  const [gatherStats, setGatherStats] = useState<GamesStatsForDay[]>([])

  useEffect(() => {
    setFetching(true)
    fetchGamesPerDay(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    ).then((gatherStats) => {
      setGatherStats(gatherStats)
      setFetching(false)
    })
  }, [startDate, endDate])

  return (
    <div>
      <Header as={"h2"} color={"blue"}>
        Matches Over Time
      </Header>
      <Container>
        <Form>
          <p>Click & drag to zoom. Double-click to zoom out fully.</p>

          <Form.Group inline>
            <Form.Field>
              <label>Start date</label>
              <SemanticDatepicker
                value={startDate}
                onChange={(event, data) => {
                  setStartDate(data.value! as Date)
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>End date</label>
              <SemanticDatepicker
                value={endDate}
                onChange={(event, data) => {
                  setEndDate(data.value! as Date)
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Container>
      <Dimmer.Dimmable dimmed={fetching} as={Segment}>
        <GathersGraph gatherStats={gatherStats} />
        <Dimmer active={fetching} inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  )
}
