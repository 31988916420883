import React, { useEffect, useState } from "react"
import { PlayerRatingEntry } from "soldat2-gatherbot-common/ratings/player"
import { fetchAllRatings } from "../util/api"

export const RatingsContext = React.createContext<{
  ratings: PlayerRatingEntry[]
  fetchingRatings: boolean
}>({
  ratings: [],
  fetchingRatings: false,
})

export const RatingsContextProvider = ({
  children,
  playlistCode,
}: {
  children: React.ReactNode
  playlistCode: string
}) => {
  const [ratings, setRatings] = useState<PlayerRatingEntry[]>([])
  const [fetchingRatings, setFetchingRatings] = useState<boolean>(false)

  useEffect(() => {
    setFetchingRatings(true)
    fetchAllRatings(playlistCode).then((ratings) => {
      setRatings(ratings)
      setFetchingRatings(false)
    })
  }, [playlistCode])

  return (
    <RatingsContext.Provider value={{ ratings, fetchingRatings }}>
      {children}
    </RatingsContext.Provider>
  )
}
