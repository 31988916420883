import {
  createSearchParams,
  NavigateOptions,
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from "react-router-dom"

import _ from "lodash"

type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions
) => void

export const useNavigatePreservingSearchParams = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const navigatePreservingSearchParams = (
    path: string,
    options?: NavigateOptions,
    additionalSearchParams?: { [paramName: string]: string | string[] }
  ) => {
    const paramNamesToPreserve = ["playlistCode"]

    const paramsToPreserve: { [paramName: string]: string | string[] } = {}

    for (let paramName of searchParams.keys()) {
      if (_.includes(paramNamesToPreserve, paramName)) {
        paramsToPreserve[paramName] = searchParams.get(paramName)!
      }
    }

    if (additionalSearchParams !== undefined) {
      for (let paramName of _.keys(additionalSearchParams)) {
        paramsToPreserve[paramName] = additionalSearchParams[paramName]
      }
    }

    console.log("inside use navigate")
    console.log(searchParams.get("playlistCode"))
    console.log(searchParams.get("test"))
    console.log(paramsToPreserve)

    navigate(
      {
        pathname: path,
        search: createSearchParams(paramsToPreserve).toString(),
      },
      options
    )
  }

  return { navigatePreservingSearchParams }
}
