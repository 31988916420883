import { Dimmer } from "semantic-ui-react"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import * as d3 from "d3"
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader"
import _ from "lodash"
import { PlayerWithRating } from "soldat2-gatherbot-common/ratings/player"
import { FullUserResponse, TierResponse } from "soldat2-gatherbot-common/api/ratings"
import { RatingCard } from "../components/RatingCard"
import { useNavigate } from "react-router"
import { AddUserToCache, getUserFromCache, UserCache } from "../hooks/useUserCache"
import { useNavigatePreservingSearchParams } from "../hooks/useNavigatePreservingSearchParams"

interface Props {
  tiers: TierResponse[]
  ratings: PlayerWithRating[]
  playlistCode: string
  addUserToCache: AddUserToCache
  userCache: UserCache
}

interface TierWithPlayers extends TierResponse {
  players: PlayerWithRating[]
}

export const TierDistributionGraph = ({
  tiers,
  ratings,
  playlistCode,
  addUserToCache,
  userCache,
}: Props) => {
  const { navigatePreservingSearchParams } = useNavigatePreservingSearchParams()

  // Do not display unranked players
  tiers = tiers.filter((tier) => tier.tierNumber !== 0)

  const figureWidth = 1000
  const figureHeight = 450

  const d3Container = useRef(null)

  const [hoverPlayfabId, setHoverPlayfabId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (ratings.length === 0) {
      return
    }

    // set the dimensions and margins of the graph
    const margin = { top: 50, right: 30, bottom: 70, left: 50 }
    const width = figureWidth - margin.left - margin.right
    const height = figureHeight - margin.top - margin.bottom
    let bars: d3.Selection<SVGRectElement, PlayerWithRating, null, undefined>[] = []

    // append the svg object to the body of the page
    let svg = d3.select(d3Container.current)
    svg.selectAll("*").remove()

    let graph = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    const data: TierWithPlayers[] = tiers.map((tier) => {
      return {
        ...tier,
        players: ratings.filter((rating) => rating.tier === tier.tier),
      }
    })

    let dataToDisplay: TierWithPlayers[] = [...data]

    const getXScale = (data: TierWithPlayers[]) => {
      return d3
        .scaleBand()
        .domain(tiers.map((tier) => tier.formattedTier))
        .range([0, width])
    }

    const getYScale = (data: TierWithPlayers[]) => {
      const maxPlayers = _.max(dataToDisplay.map((item) => item.players.length))

      return d3
        .scaleLinear()
        .domain([0, maxPlayers! + 1])
        .range([height, 0])
    }

    // const filterData = (data: TierWithPlayers[], minX: number, maxX: number) => {
    //   return _.filter(data, (d) => {
    //     return d.tierNumber >= minX && d.tierNumber <= maxX
    //   })
    // }

    let x = getXScale(dataToDisplay)
    let y = getYScale(dataToDisplay)

    const drawXAxis = () => {
      const xAxis = graph
        .append("g")
        .attr("id", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))

      xAxis
        .selectAll("text")
        .attr("y", 0)
        .attr("x", 9)
        .attr("dy", ".35em")
        .attr("transform", "rotate(90)")
        .attr("text-anchor", "start")
    }

    drawXAxis()

    // const yAxis = graph.append("g").call(d3.axisLeft(y))

    // Add a rectangular clipPath: everything out of this area won't be drawn. This ensures that as we zoom into
    // our graph we won't see any lines being drawn outside of the axis area
    const defs = graph.append("defs")
    defs
      .append("svg:clipPath")
      .attr("id", "clip")
      .append("svg:rect")
      .attr("width", width)
      .attr("height", height)
      .attr("x", 0)
      .attr("y", 0)

    const group = graph.append("g")

    const drawBars = () => {
      const playerBarHeight = y(0) - y(1)

      _.forEach(dataToDisplay, (item, j) => {
        _.forEach(item.players, (player, i) => {
          // Add the line to the above group
          const gameBar = group
            .append("rect")
            .datum(player)
            .attr("id", `gameBar${player.playfabId}`)
            .attr("fill", item.tierColor)
            .attr("stroke", "black")
            .attr("stroke-width", "1")
            .attr("x", x(item.formattedTier)!)
            .attr("y", y(i) - playerBarHeight)
            .attr("height", playerBarHeight)
            .attr("width", x.bandwidth())

          gameBar.on("mouseover", (e: d3.ClientPointEvent, d: PlayerWithRating) => {
            const playerCardWidth = 250
            const playerCardHeight = 250

            // Make sure tooltips do not leave the bounds of the figure
            const tooltipX = Math.max(
              0,
              Math.min(width - playerCardWidth, x(item.formattedTier)! - playerCardWidth / 2)
            )
            const tooltipY = Math.max(0, Math.min(height - playerCardHeight, y(i)))

            // This is controlled by React.createPortal in the render method of this component
            group
              .insert("foreignObject")
              .attr("id", `playerHoverBox${player.playfabId}`)
              .attr("x", tooltipX)
              .attr("y", tooltipY)
              .attr("width", playerCardWidth)
              .attr("height", playerCardHeight)

              // This ensures that when we draw the game box, we don't capture a mouseout event and
              // immediately remove it
              .attr("pointer-events", "none")

            addUserToCache(player.playfabId, playlistCode, 10)
            setHoverPlayfabId(player.playfabId)
          })

          const handleClick = (e: d3.ClientPointEvent, d: PlayerWithRating) => {
            navigatePreservingSearchParams(`/stats/${d.playfabId}`)
          }

          gameBar
            .on("mouseout", (e: d3.ClientPointEvent, d: PlayerWithRating) => {
              setHoverPlayfabId(undefined)
              graph.select(`#playerHoverBox${d.playfabId}`).remove()
            })
            .on("click", handleClick)

          bars.push(gameBar)
        })
      })
    }

    drawBars()
  }, [ratings])

  let portal = null
  if (hoverPlayfabId !== undefined) {
    const hoverBoxElem = document.getElementById(`playerHoverBox${hoverPlayfabId}`)!

    let user: FullUserResponse | undefined = getUserFromCache(
      userCache,
      hoverPlayfabId,
      playlistCode
    )

    // Sometimes this is null if the user hovers very quickly
    if (hoverBoxElem !== null) {
      portal = ReactDOM.createPortal(
        <RatingCard user={user} interactive={false} numLastGames={0} setNumLastGames={() => {}} />,
        hoverBoxElem
      )
    }
  }

  return ratings.length === 0 ? (
    <Dimmer active inverted>
      <Loader inverted>Loading...</Loader>
    </Dimmer>
  ) : (
    <div>
      {portal}
      <div className={"svg-container"}>
        <svg
          ref={d3Container}
          preserveAspectRatio="xMinYMin meet"
          viewBox={`0 0 ${figureWidth} ${figureHeight}`}
          className={"svg-content-responsive"}
        />
      </div>
    </div>
  )
}
