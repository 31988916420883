export enum GameModeName {
  CaptureTheFlag = "CaptureTheFlag",
  TeamDeathmatch = "TeamDeathmatch",
  Domination = "Domination",
  Deathmatch = "Deathmatch",
}

export function isTeamGameModeName(gameModeName: string) {
  return (
    gameModeName === GameModeName.Domination ||
    gameModeName === GameModeName.TeamDeathmatch ||
    gameModeName === GameModeName.CaptureTheFlag
  )
}

export function isFreeForAllGameModeName(gameModeName: string) {
  return gameModeName === GameModeName.Deathmatch
}
