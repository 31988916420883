import * as React from "react"
import { Grid, List } from "semantic-ui-react"
import { SoldatTeam } from "soldat2-gatherbot-common/game/soldatTeam"
import {
  getBlueScore,
  getRedScore,
  ResolvedFinishedTeamRound,
} from "soldat2-gatherbot-common/game/resolved/round"
import { MatchResult } from "soldat2-gatherbot-common/game/matchResult"
import { GameModeName } from "soldat2-gatherbot-common/game/gameMode"
import { getColorForMatchResult, rgbBlue, rgbGray, rgbRed } from "../util/colors"

export const TeamResultColumn = ({
  gameMode,
  alpha,
  result,
  blueScore,
  redScore,
  rounds,
  horizontal,
}: {
  alpha: number
  gameMode: GameModeName
  result: MatchResult
  blueScore: number
  redScore: number
  rounds: ResolvedFinishedTeamRound[]
  horizontal: boolean
}) => {
  return (
    <Grid.Column
      width={5}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      <List
        horizontal={horizontal}
        // divided
      >
        <List.Item
          style={{
            backgroundColor: getColorForMatchResult(result, alpha),
            padding: "5px",
          }}
        >
          <List.Content>
            <b>Winner: {result.winner}</b>
            {rounds.length > 0 ? (
              <React.Fragment>
                <br />
                {blueScore} - {redScore}
              </React.Fragment>
            ) : null}
          </List.Content>
        </List.Item>
        {rounds.map((round) => {
          return (
            <List.Item
              key={round.startTime}
              style={{
                backgroundColor: getColorForMatchResult(round.result, alpha),
                padding: "5px",
              }}
            >
              <List.Content>
                <b>{round.mapName}</b>: {getBlueScore(gameMode, round)} -{" "}
                {getRedScore(gameMode, round)}
              </List.Content>
            </List.Item>
          )
        })}
      </List>
    </Grid.Column>
  )
}
