import {
  PlayerWithKillsAndRating,
  ResolvedFinishedTeamGame,
} from "soldat2-gatherbot-common/game/resolved/game"
import { getTeams, SoldatTeam } from "soldat2-gatherbot-common/game/soldatTeam"
import { getColorForPlayerMatchResult, rgbBlue, rgbRed } from "../../util/colors"
import { PlayersColumn, TeamInfo } from "../PlayersColumn"
import { TeamResultColumn } from "../TeamResultColumn"
import { ResolvedFinishedTeamGameForPlayer } from "soldat2-gatherbot-common/game/resolved/gameForPlayer"
import "./GamePopup.css"
import { Grid, Icon, List, Popup } from "semantic-ui-react"
import moment from "moment"

export const TeamGameDetails = ({
  game,
  alpha,
}: {
  game: ResolvedFinishedTeamGame
  alpha: number
}) => {
  const teams = getTeams(game.players)

  const bluePlayers: PlayerWithKillsAndRating[] = teams[SoldatTeam.BLUE]
  const redPlayers: PlayerWithKillsAndRating[] = teams[SoldatTeam.RED]

  const teamsConfig = {
    Blue: {
      teamName: "Blue",
      players: bluePlayers,
      color: rgbBlue(alpha),
      winProbability: game.teamWinProbabilities[SoldatTeam.BLUE],
      floated: "left",
    } as TeamInfo,
    Red: {
      teamName: "Red",
      players: redPlayers,
      color: rgbRed(alpha),
      winProbability: game.teamWinProbabilities[SoldatTeam.RED],
      floated: "right",
    } as TeamInfo,
  }

  return (
    <Grid
      columns={"equal"}
      // divided
      // style={{width: "600px"}}
      // container // Provide a fixed-width container
      // padded
      textAlign={"center"}
    >
      <Grid.Row>
        <PlayersColumn team={teamsConfig.Blue} playerKillsAndDeaths={game.playerKillsAndDeaths} />
        <TeamResultColumn
          gameMode={game.gameMode}
          horizontal={false}
          alpha={alpha}
          blueScore={game.teamRoundWins[SoldatTeam.BLUE]}
          redScore={game.teamRoundWins[SoldatTeam.RED]}
          result={game.result!}
          rounds={game.rounds}
        />
        <PlayersColumn team={teamsConfig.Red} playerKillsAndDeaths={game.playerKillsAndDeaths} />
      </Grid.Row>
    </Grid>
  )
}
export const TeamGamePopupForPlayer = ({
  game,
  interactive,
}: {
  game: ResolvedFinishedTeamGameForPlayer
  interactive: boolean
}) => {
  const kd = game.playerKillsAndDeaths[game.playfabId]
  const alpha = 0.6

  const blueRoundWins = game.teamRoundWins[SoldatTeam.BLUE]
  const redRoundWins = game.teamRoundWins[SoldatTeam.RED]

  return (
    <Popup
      flowing
      // Content is in a separate component in order only trigger loading of user data once someone
      // hovers over the popup
      content={<TeamGameDetails game={game} alpha={alpha} />}
      mouseEnterDelay={500}
      mouseLeaveDelay={250}
      // open={true} // Useful for debugging, must hover over before changing this to avoid portal error
      key={game.startTime}
      header={
        <div className={"header"} style={{ textAlign: "center" }}>
          Game #{game.gameNumberForPlayerInPlaylist} played on{" "}
          {moment(game.startTime).format("DD-MM-YYYY HH:mm:ss")} (
          {moment.duration(moment().valueOf() - game.startTime).humanize()} ago)
        </div>
      }
      trigger={
        <List.Item
          style={{ backgroundColor: getColorForPlayerMatchResult(game.matchResult, alpha) }}
          // className={"monospaced"}
        >
          {interactive ? (
            <Grid columns="equal" padded>
              <Grid.Row
                style={{
                  padding: "3px",
                }}
              >
                <Grid.Column>
                  <Icon name={"flag"} fitted />
                </Grid.Column>
                <Grid.Column>
                  {blueRoundWins} - {redRoundWins}
                </Grid.Column>
                <Grid.Column width={4}>{(kd.kills / kd.deaths).toFixed(2)} K/D</Grid.Column>
                <Grid.Column>{(game.playerTeamWinProbability * 100).toFixed(1)}%</Grid.Column>
                <Grid.Column width={5}>
                  {moment.duration(moment().valueOf() - game.startTime).humanize()} ago
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <div>
              {blueRoundWins} - {redRoundWins} | {(kd.kills / kd.deaths).toFixed(2)} K/D |{" "}
              {(game.playerTeamWinProbability * 100).toFixed(1)}% |{" "}
              {moment.duration(moment().valueOf() - game.startTime).humanize()} ago
            </div>
          )}
        </List.Item>
      }
      style={{
        width: "700px",
      }}
    />
  )
}
