import { API_URL } from "./constants"
import { FullUserResponse, TierResponse } from "soldat2-gatherbot-common/api/ratings"
import { WeaponStatsPoint } from "soldat2-gatherbot-common/ratings/stats"
import {
  AllResolvedGamesResponse,
  GamesStatsForDay,
  ResolvedGamesForPlayerResponse,
} from "soldat2-gatherbot-common/api/games"
import { PlayerRatingEntry } from "soldat2-gatherbot-common/ratings/player"
import { ResolvedFinishedGame } from "soldat2-gatherbot-common/game/resolved/game"
import { Playlist } from "soldat2-gatherbot-common/game/playlist"
import { GameModeSettings } from "soldat2-gatherbot-common/game/gamemode/settings"

export const fetchAllRatings = async (playlistCode: string): Promise<PlayerRatingEntry[]> => {
  return (await fetch(`${API_URL}/ratings/all?playlistCode=${playlistCode}`)).json()
}

export const fetchPlaylists = async (): Promise<Playlist<GameModeSettings>[]> => {
  return (await fetch(`${API_URL}/matchmaking/playlist`)).json()
}

export const fetchUser = async (
  playfabId: string,
  playlistCode: string,
  gamesLimit: number
): Promise<FullUserResponse> => {
  return (
    await fetch(
      `${API_URL}/ratings/playfab/${playfabId}?playlistCode=${playlistCode}&withGames=true&gamesLimit=${gamesLimit}`
    )
  ).json()
}

export const fetchGame = async (startTime: number): Promise<ResolvedFinishedGame> => {
  return (await fetch(`${API_URL}/game/${startTime}?withEvents=true`)).json()
}

export const fetchWeaponStats = async (): Promise<WeaponStatsPoint[]> => {
  // TODO: playlist code?
  return (await fetch(`${API_URL}/weapons`)).json()
}

export const fetchGamesPerDay = async (
  startDate: string,
  endDate: string
): Promise<GamesStatsForDay[]> => {
  // TODO: playlist code?
  return (
    await fetch(`${API_URL}/game/history/per_day?startDate=${startDate}&endDate=${endDate}`)
  ).json()
}

export const fetchTiers = async (): Promise<TierResponse[]> => {
  return (await fetch(`${API_URL}/ratings/tiers`)).json()
}

export const fetchAllResolvedGames = async (): Promise<AllResolvedGamesResponse> => {
  // TODO: playlist code?
  return (await fetch(`${API_URL}/game/history`)).json()
}

export const fetchAllResolvedGamesForPlayer = async (
  playfabId: string,
  playlistCode: string,
  limit: number
): Promise<ResolvedGamesForPlayerResponse> => {
  return (
    await fetch(
      `${API_URL}/game/history/for_player/${playfabId}?playlistCode=${playlistCode}&limit=${limit}`
    )
  ).json()
}
