import * as React from "react"
import { useContext, useEffect, useRef, useState } from "react"
import * as d3 from "d3"
import { D3BrushEvent } from "d3"
import moment, { Moment } from "moment"
import _ from "lodash"
import {
  ResolvedFinishedGameForPlayer,
  ResolvedFinishedTeamGameForPlayer,
} from "soldat2-gatherbot-common/game/resolved/gameForPlayer"
import { getRankIconUrl } from "../util/ranks"
import ReactDOM from "react-dom"
import { MatchResultForPlayer } from "soldat2-gatherbot-common/game/matchResult"
import { TiersContext } from "../context/tiers"
import { GameDetails } from "../components/game_details/base"

interface Props {
  gamesPerPlayer: { [playfabId: string]: ResolvedFinishedGameForPlayer[] }
  xAxisType: "matches" | "time"
  numGamesToDisplay: number
  showLegend: boolean
  figureHeight: number

  getGameNumber: (game: ResolvedFinishedGameForPlayer) => number
}

export const TierChangesGraph = ({
  gamesPerPlayer,
  xAxisType,
  numGamesToDisplay,
  showLegend,
  figureHeight,
  getGameNumber,
}: Props) => {
  const { tiers } = useContext(TiersContext)

  const figureWidth = 1000

  const d3Container = useRef(null)

  const [hoverMatch, setHoverMatch] = useState<ResolvedFinishedTeamGameForPlayer | undefined>(
    undefined
  )

  useEffect(() => {
    let games = _.flatten(_.values(gamesPerPlayer)).sort((game) => game.startTime)

    if (games.length === 0 || tiers.length === 0) {
      return
    }

    const lastGameNumber = getGameNumber(games[games.length - 1])

    games = games.filter((game) => getGameNumber(game) >= lastGameNumber - numGamesToDisplay)

    const legendWidth = showLegend ? 100 : 0

    // set the dimensions and margins of the graph
    const margin = { top: 20, right: 30, bottom: 30, left: 70 }
    const width = figureWidth - margin.left - margin.right - legendWidth
    const height = figureHeight - margin.top - margin.bottom
    const curveType = xAxisType === "matches" ? d3.curveLinear : d3.curveStep
    const lines: d3.Selection<
      SVGPathElement,
      ResolvedFinishedTeamGameForPlayer[],
      null,
      undefined
    >[] = []
    const color = d3.scaleOrdinal([...d3.schemeCategory10, ...d3.schemeTableau10])

    // append the svg object to the body of the page
    let svg = d3.select(d3Container.current)
    svg.selectAll("*").remove()

    let graph = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    let dataToDisplay = games

    const players = _.keys(gamesPerPlayer)

    const getXScale = (data: ResolvedFinishedTeamGameForPlayer[]) => {
      if (xAxisType === "time") {
        const [min, max] = d3.extent(data, (d) => moment(d.startTime)) as Moment[]

        return d3.scaleTime().domain([min, max]).range([0, width])
      } else if (xAxisType === "matches") {
        const [min, max] = d3.extent(data, (d) => getGameNumber(d)) as number[]

        return d3
          .scaleLinear()
          .domain([min, max + 1])
          .range([0, width])
      } else {
        throw Error(`Unexpected xAxisType: ${xAxisType}`)
      }
    }

    const getYScale = (data: ResolvedFinishedTeamGameForPlayer[]) => {
      const maxRating = _.max(data.map((d) => d.newRating.tierNumber))
      const minRating = _.min(data.map((d) => d.newRating.tierNumber))

      return d3
        .scaleLinear()
        .domain([minRating! - 1, maxRating! + 1])
        .range([height, 0])
        .nice()
    }

    const getXValue = (d: ResolvedFinishedTeamGameForPlayer) => {
      if (xAxisType === "time") {
        return moment(d.startTime)
      } else if (xAxisType === "matches") {
        return getGameNumber(d)
      } else {
        throw Error(`Unexpected xAxisType: ${xAxisType}`)
      }
    }

    const filterData = (
      data: ResolvedFinishedTeamGameForPlayer[],
      minX: number | Date,
      maxX: number | Date
    ) => {
      if (xAxisType === "time") {
        return _.filter(data, (d) => {
          const date = moment(d.startTime).toDate()
          return date >= minX && date <= maxX
        })
      } else if (xAxisType === "matches") {
        return _.filter(data, (d) => getGameNumber(d) >= minX && getGameNumber(d) <= maxX)
      } else {
        throw Error(`Unexpected xAxisType: ${xAxisType}`)
      }
    }

    const getLine = () => {
      return d3
        .line<ResolvedFinishedTeamGameForPlayer>()
        .x((d) => x(getXValue(d)))
        .y((d) => y(d.newRating.tierNumber))
        .curve(curveType)
    }

    let x = getXScale(dataToDisplay)
    let y = getYScale(dataToDisplay)

    function formatRankingValue(tierNumber: number) {
      if (tierNumber === 0) {
        return "Unranked"
      }

      const found = _.find(tiers, (tier) => tier.tierNumber === tierNumber)
      if (found !== undefined) {
        return found.formattedTier
      } else {
        return ""
      }
    }

    const getXAxis = () => {
      return d3.axisBottom(x)
    }

    const getYAxis = () => {
      const yAxisTicks = y.ticks().filter((tick) => Number.isInteger(tick))

      return d3
        .axisLeft(y)
        .tickValues(yAxisTicks)
        .tickFormat((d) => formatRankingValue(d.valueOf()))
    }

    const xAxis = graph
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(getXAxis())

    const yAxis = graph.append("g").call(getYAxis())

    // Add a rectangular clipPath: everything out of this area won't be drawn. This ensures that as we zoom into
    // our graph we won't see any lines being drawn outside of the axis area
    const defs = graph.append("defs")
    defs
      .append("svg:clipPath")
      .attr("id", "clip")
      .append("svg:rect")
      .attr("width", width)
      .attr("height", height)
      .attr("x", 0)
      .attr("y", 0)

    const legend = graph.append("g")

    // Create a group where both the line and the brush are drawn, link it to the earlier clip path
    const group = graph.append("g").attr("clip-path", "url(#clip)")

    // Add a brush for selecting an area to zoom into
    const brush = d3
      .brushX()
      // Cover the full extent of the graph
      .extent([
        [0, 0],
        [width, height],
      ])
      // Each time the brush selection changes, trigger the 'updateChart' function
      .on("end", updateChart)

    const grad = defs
      .append("linearGradient")
      .attr("id", "rankingsGradient")
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "100%")
      .attr("y2", "0%")

    // Generate the brush (drawn in the same group which contains the line, using the clip area)
    group.append("g").attr("class", "brush").attr("id", "brush").call(brush)

    const lineWidth = d3.scaleLinear().domain([0, 500]).range([3, 1]).clamp(true)

    for (let i = 0; i < players.length; i++) {
      const playfabId = players[i]

      const playerMatches = gamesPerPlayer[playfabId]

      const displayName = playerMatches[0].displayName

      // Add the line to the above group
      const line = group
        .append("path")
        .datum(playerMatches)
        .attr("fill", "none")
        .attr("stroke", color(playfabId))
        .attr("stroke-width", (d) => lineWidth(dataToDisplay.length))
        // .attr("stroke-dasharray", "4 1")
        .attr("opacity", 0.8)
        .attr("d", getLine())

      line.on("mousemove", (e: d3.ClientPointEvent, d: ResolvedFinishedTeamGameForPlayer[]) => {
        const [clientX, clientY] = d3.pointer(e)

        const xPoint = x.invert(clientX)
        // const yPoint = y.invert(clientY)

        let index

        if (xPoint instanceof Date) {
          index = d3.bisect(
            playerMatches.map((d) => d.startTime),
            xPoint.valueOf()
          )
        } else {
          index = d3.bisect(
            playerMatches.map((d) => d.gameNumberForPlayerInPlaylist),
            xPoint
          )
        }

        const datum = playerMatches[index]

        graph.select(`#Tooltip${playfabId}`).remove()

        line.attr("stroke-width", (d) => lineWidth(dataToDisplay.length) * 2)
        const tooltipGroup = graph.append("g").attr("id", `Tooltip${playfabId}`)

        const tooltipText = tooltipGroup
          .append("text")
          .attr("id", `TooltipText${playfabId}`)
          .datum(playfabId)
          .attr("x", clientX + 10)
          .attr("y", clientY - 15) // 100 is where the first dot appears. 25 is the distance between dots
          .style("fill", color(playfabId))
          .text(`${displayName}: ${formatRankingValue(datum.newRating.tierNumber)}`)
          .attr("text-anchor", "left")
          .style("alignment-baseline", "middle")
          .style("font-size", "14px")

        const tooltipPadding = 3

        tooltipGroup
          .insert("rect", `#TooltipText${playfabId}`)
          .style("fill", "rgb(0, 0, 0, 0.7)")
          .attr("x", clientX + 10 - tooltipPadding)
          .attr("y", clientY - 10 - tooltipText.node()!.getBBox().height)
          .attr("width", tooltipText.node()!.getBBox().width + tooltipPadding * 2)
          .attr("height", tooltipText.node()!.getBBox().height + tooltipPadding * 2)
      })

      line.on("mouseout", (e: d3.ClientPointEvent, d: ResolvedFinishedTeamGameForPlayer[]) => {
        line.attr("stroke-width", (d) => lineWidth(dataToDisplay.length))
        graph.select(`#Tooltip${playfabId}`).remove()
      })

      if (showLegend) {
        legend
          .append("circle")
          .datum(playfabId)
          .attr("cx", width + 20)
          .attr("cy", (d) => i * 20) // 100 is where the first dot appears. 25 is the distance between dots
          .attr("r", 6)
          .style("fill", (d) => color(d))

        legend
          .append("text")
          .datum(playfabId)
          .attr("x", width + 40)
          .attr("y", (d) => i * 20) // 100 is where the first dot appears. 25 is the distance between dots
          .style("fill", (d) => color(d))
          .text((d) => displayName)
          .attr("text-anchor", "left")
          .style("alignment-baseline", "middle")
          .style("font-size", "14px")
      }

      lines.push(line)
    }

    const transitionGraph = (data: ResolvedFinishedTeamGameForPlayer[]) => {
      grad
        .selectAll("stop")
        .data(tiers)
        .enter()
        .append("stop")
        .style("stop-color", (d) => d.tierColor)
        .attr("offset", (d, i) => 100 * (d.tierNumber / tiers[tiers.length - 1].tierNumber) + "%")

      group.selectAll(".shading").transition().duration(1000).attr("opacity", 0).remove()

      const rankingShading = group
        .insert("rect", "#brush")
        .attr("class", "shading")
        .attr("opacity", 0)
        .attr("x", 0)
        .attr("y", y(tiers[tiers.length - 1].tierNumber))
        .attr("width", width)
        .attr("height", y(tiers[0].tierNumber) - y(tiers[tiers.length - 1].tierNumber))
        .attr("fill", "url(#rankingsGradient)")
        .transition()
        .duration(1000)
        .attr("opacity", 0.2)

      // Background colors
      const spaceBetweenTicks = y(0) - y(1)
      const tierImageWidth = spaceBetweenTicks

      const rankingImagesSelection = group.selectAll(".background-rank-images").data(tiers)

      rankingImagesSelection
        .join(
          (enter) =>
            enter
              .insert("image", "path")
              .attr("width", tierImageWidth)
              .attr("height", tierImageWidth)
              .attr("x", width / 2 - tierImageWidth / 2)
              .attr("y", (d) => y(d.tierNumber) - tierImageWidth / 2),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("class", "background-rank-images")
        .attr("href", (d) => getRankIconUrl(d.tier))

        .style("opacity", 0.5)
        .transition()
        .duration(1000)
        .attr("width", tierImageWidth)
        .attr("height", tierImageWidth)
        .attr("x", width / 2 - tierImageWidth / 2)
        .attr("y", (d) => y(d.tierNumber) - tierImageWidth / 2)

      // Transition to new axis using the new domains
      yAxis.transition().duration(1000).call(getYAxis())

      xAxis.transition().duration(1000).call(getXAxis())

      // yAxis.call(getYAxis)
      // xAxis.call(getXAxis)

      // Transition to a new line. If the domain was 0-100 and then changed to 0-50, this essentially creates the
      // desired "zooming" effect by mapping a smaller domain over the same range (pixels on the screen).
      // _.keys(weaponStats[0].weapons).map((weaponName, i) => {
      for (let line of lines) {
        line
          .transition()
          .duration(1000)
          .attr("d", getLine())
          .attr("stroke-width", (d) => lineWidth(dataToDisplay.length))
      }

      const stars = group
        .selectAll(".star")
        .data(data, (d) => (d as ResolvedFinishedTeamGameForPlayer).gameNumberForPlayerInPlaylist)

      const removeMatchTooltip = () => {
        setHoverMatch(undefined)
        group.select(`#matchHoverBox`).remove()
      }

      const handleMouseOverPoint = (
        e: d3.ClientPointEvent,
        d: ResolvedFinishedTeamGameForPlayer
      ) => {
        removeMatchTooltip()

        const tooltipWidth = 500
        const tooltipHeight = 200

        // Make sure tooltips do not leave the bounds of the figure
        const tooltipX = Math.max(
          0,
          Math.min(width - tooltipWidth, x(getXValue(d)) - tooltipWidth / 2)
        )
        const tooltipY = Math.max(
          0,
          Math.min(height - tooltipHeight, y(d.newRating.tierNumber) - tooltipHeight / 2 + 5)
        )

        // This is controlled by React.createPortal in the render method of this component
        group
          .append("foreignObject")
          .attr("id", `matchHoverBox`)
          .attr("x", tooltipX)
          .attr("y", tooltipY)
          .attr("width", tooltipWidth)
          .attr("height", tooltipHeight)
          .on("mouseout", (d) => removeMatchTooltip())

        // Append the hovered-over star at the end of the group, so that its visible above our box. Necessary
        // to maintain the on-hover effect
        // group.append(() => {
        //     return d3.select(`#star${d.match_number}`).remove().node()
        // })

        setHoverMatch(d)
      }

      // Enter selection; new circles should fade in
      const starWidth = d3.scaleLinear().domain([0, 200]).range([20, 0]).clamp(true)

      const getStarWidth = (d: ResolvedFinishedTeamGameForPlayer) => {
        return starWidth(dataToDisplay.length)
      }

      const starsEnterAndUpdate = stars
        .join(
          (enter) => {
            const stuff = enter
              .append("image")
              .attr("x", (d) => x(getXValue(d)) - getStarWidth(d) / 2)
              .attr("y", (d) => y(d.newRating.tierNumber) - getStarWidth(d) / 2)
              .attr("width", (d) => getStarWidth(d))
              .attr("height", (d) => getStarWidth(d))
              .attr("opacity", 0)

            stuff.transition().duration(1000).attr("opacity", 1)

            return stuff
          },
          (update) => update,
          (exit) => exit.transition().duration(1000).style("opacity", 0).remove()
        )
        .attr("id", (d) => `star${d.gameNumberForPlayerInPlaylist}`)
        .attr("class", "star")
        .on("mouseover", handleMouseOverPoint)
        .transition()
        .duration(1000)
        .attr("x", (d) => x(getXValue(d)) - getStarWidth(d) / 2)
        .attr("y", (d) => y(d.newRating.tierNumber) - getStarWidth(d) / 2)
        .attr("width", (d) => getStarWidth(d))
        .attr("height", (d) => getStarWidth(d))
        .attr("opacity", 1)

      // starsEnterAndUpdate.filter(d => d.mvp !== MvpResult.MVP).attr("href", "/gray_star.png")
      // starsEnterAndUpdate.filter(d => d.mvp === MvpResult.MVP).attr("href", "/gold_star.png")

      // starsEnterAndUpdate.filter(d => d.match_result !== MatchResult.VICTORY).style("outline", "1px solid white")
      // starsEnterAndUpdate.filter(d => d.match_result === MatchResult.VICTORY).style("outline", "1px solid white")

      starsEnterAndUpdate
        .filter((d) => d.matchResult !== MatchResultForPlayer.VICTORY)
        .attr("href", "/images/gray_star.png")
      starsEnterAndUpdate
        .filter((d) => d.matchResult === MatchResultForPlayer.VICTORY)
        .attr("href", "/images/gold_star.png")
    }

    function updateChart(event: D3BrushEvent<unknown>, d: unknown) {
      // This is the area that has been selected
      const extent = event.selection as [number, number]

      if (extent) {
        // If something was selected, filter the data to display
        const [minX, maxX] = [x.invert(extent[0]), x.invert(extent[1])]

        dataToDisplay = filterData(games, minX, maxX)

        x = getXScale(dataToDisplay)
        y = getYScale(dataToDisplay)

        // This remove the grey brush area as soon as the selection has been done
        // group.select(".brush").call(brush.move, null)
        brush.move(group.select(".brush"), null)
      }

      // Update axis and line position
      transitionGraph(dataToDisplay)
    }

    // If user double click, reinitialize the chart by zooming out over the unfiltered data
    graph.on("dblclick", () => {
      dataToDisplay = [...games]

      x = getXScale(dataToDisplay)
      y = getYScale(dataToDisplay)
      transitionGraph(dataToDisplay)
    })

    transitionGraph(dataToDisplay)
  }, [gamesPerPlayer, tiers, numGamesToDisplay, xAxisType])

  let portal = null
  if (hoverMatch !== undefined) {
    const hoverBoxElem = document.getElementById(`matchHoverBox`)

    if (hoverBoxElem !== null) {
      portal = ReactDOM.createPortal(<GameDetails game={hoverMatch} alpha={1.0} />, hoverBoxElem)
    }
  }

  return (
    <div>
      {portal}
      <div className={"svg-container"}>
        <svg
          ref={d3Container}
          preserveAspectRatio="xMinYMin meet"
          viewBox={`0 0 ${figureWidth} ${figureHeight}`}
          className={"svg-content-responsive"}
        />
      </div>
    </div>
  )
}
