import { ResolvedFinishedFreeForAllGameForPlayer } from "soldat2-gatherbot-common/game/resolved/gameForPlayer"
import { getColorForPlayerMatchResult } from "../../util/colors"
import { formatKillsAndDeaths } from "soldat2-gatherbot-common/game/util/formatting"
import "./GamePopup.css"
import { Grid, Icon, List, Popup } from "semantic-ui-react"
import moment from "moment"
import _ from "lodash"

export const DeathmatchGameDetails = ({
  game,
  alpha,
}: {
  game: ResolvedFinishedFreeForAllGameForPlayer
  alpha: number
}) => {
  const playfabIdToDisplayName = _.fromPairs(
    game.players.map((player) => [player.playfabId, player.displayName])
  )

  return (
    <Grid columns={"equal"} textAlign={"center"}>
      <Grid.Row>
        <Grid.Column
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          <List horizontal={false}>
            <List.Item
              style={{
                backgroundColor: getColorForPlayerMatchResult(game.matchResult, alpha),
                padding: "5px",
              }}
            >
              <List.Content>
                <b>
                  {game.result?.isTie
                    ? "Game Result: Tie"
                    : `Game Result: ${playfabIdToDisplayName[game.result?.winner!]} Won`}
                </b>
              </List.Content>
            </List.Item>
            {game.rounds.map((round) => {
              return (
                <List.Item
                  key={round.startTime}
                  style={{
                    backgroundColor: getColorForPlayerMatchResult(round.roundResult, alpha),
                    padding: "5px",
                  }}
                >
                  <List.Content>
                    <b>{round.mapName}</b>
                    <br />
                    {game.players.map((player) => {
                      const kd = round.playerKillsAndDeaths[player.playfabId]

                      return (
                        <span>
                          <b>{playfabIdToDisplayName[player.playfabId]}</b>{" "}
                          {formatKillsAndDeaths(kd.kills, kd.deaths)}
                          <br />
                        </span>
                      )
                    })}
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
export const DeathmatchGamePopupForPlayer = ({
  game,
  interactive,
}: {
  game: ResolvedFinishedFreeForAllGameForPlayer
  interactive: boolean
}) => {
  const kd = game.playerKillsAndDeaths[game.playfabId]
  const alpha = 0.6

  return (
    <Popup
      flowing
      // Content is in a separate component in order only trigger loading of user data once someone
      // hovers over the popup
      content={<DeathmatchGameDetails game={game} alpha={alpha} />}
      mouseEnterDelay={500}
      mouseLeaveDelay={250}
      // open={true} // Useful for debugging, must hover over before changing this to avoid portal error
      key={game.startTime}
      header={
        <div className={"header"} style={{ textAlign: "center" }}>
          Game #{game.gameNumberForPlayerInPlaylist} played on{" "}
          {moment(game.startTime).format("DD-MM-YYYY HH:mm:ss")} (
          {moment.duration(moment().valueOf() - game.startTime).humanize()} ago)
        </div>
      }
      trigger={
        <List.Item
          style={{ backgroundColor: getColorForPlayerMatchResult(game.matchResult, alpha) }}
          // className={"monospaced"}
        >
          {interactive ? (
            <Grid columns="equal" padded>
              <Grid.Row
                style={{
                  padding: "3px",
                }}
              >
                <Grid.Column>
                  <Icon name={"crosshairs"} fitted />
                </Grid.Column>
                <Grid.Column width={5}>{game.matchResult}</Grid.Column>
                <Grid.Column width={4}>{(kd.kills / kd.deaths).toFixed(2)} K/D</Grid.Column>
                <Grid.Column>{(game.playerTeamWinProbability * 100).toFixed(1)}%</Grid.Column>
                <Grid.Column width={5}>
                  {moment.duration(moment().valueOf() - game.startTime).humanize()} ago
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <div>
              {game.matchResult} | {(kd.kills / kd.deaths).toFixed(2)} K/D |{" "}
              {(game.playerTeamWinProbability * 100).toFixed(1)}% |{" "}
              {moment.duration(moment().valueOf() - game.startTime).humanize()} ago
            </div>
          )}
        </List.Item>
      }
      style={{
        width: "500px",
      }}
    />
  )
}
