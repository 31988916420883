import React from "react"
import "./App.css"
import "semantic-ui-css/semantic.min.css"
import { Container, Icon, Label, Menu } from "semantic-ui-react"
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import logo from "./images/s2_gather.png"
import { RankDistributionPage } from "./pages/RankDistributionPage"
import { TierDistributionPage } from "./pages/TierDistributionPage"
import { TierChangesPage } from "./pages/TierChangesPage"
import { PlayerSearchInput } from "./components/PlayerSearchInput"
import { GlobalApiDataContextProvider } from "./context/globalApiData"
import { GathersPage } from "./pages/GathersPage"
import { PlayerStatsModal } from "./pages/PlayerStatsModal"
import { useUserCache } from "./hooks/useUserCache"
import { PlaylistCodePicker } from "./components/PlaylistCodePicker"
import { usePlaylists } from "./hooks/usePlaylistCode"
import { useNavigatePreservingSearchParams } from "./hooks/useNavigatePreservingSearchParams"

function App() {
  const navigate = useNavigate()
  const location = useLocation()

  const { navigatePreservingSearchParams } = useNavigatePreservingSearchParams()

  const { userCache, addUserToCache } = useUserCache()

  const { playlists, playlistCode, setPlaylistCode } = usePlaylists()

  if (playlistCode === null) {
    console.log("redirecting")
    return <Navigate to={`${location.pathname}?playlistCode=CTF-Standard-6`} />
  }

  return (
    <GlobalApiDataContextProvider playlistCode={playlistCode}>
      <div>
        <Menu fixed={"top"} color={"blue"}>
          <Menu.Item>
            <img src={logo} alt={"logo"} />
          </Menu.Item>
          <Menu.Item
            name="tiers"
            active={location.pathname === "/"}
            onClick={() => navigatePreservingSearchParams("/")}
          >
            <Icon name={"chart bar"} />
            Tier Distribution
          </Menu.Item>
          <Menu.Item
            name="ranks"
            active={location.pathname === "/ranks"}
            onClick={() => navigatePreservingSearchParams("/ranks")}
          >
            <Icon name={"bell"} />
            Rank Distribution
          </Menu.Item>
          <Menu.Item
            name="tierChanges"
            active={location.pathname === "/tier_changes"}
            onClick={() => navigatePreservingSearchParams("/tier_changes")}
          >
            <Icon name={"chart line"} />
            Rank Changes
          </Menu.Item>
          {/*<Menu.Item*/}
          {/*  name="weapons"*/}
          {/*  active={location.pathname === "/weapons"}*/}
          {/*  onClick={() => navigatePreservingSearchParams("/weapons")}*/}
          {/*>*/}
          {/*  <Icon name={"crosshairs"} />*/}
          {/*  Weapons*/}
          {/*</Menu.Item>*/}
          <Menu.Item
            name="gathers"
            active={location.pathname === "/matches"}
            onClick={() => navigatePreservingSearchParams("/matches")}
          >
            <Icon name={"flag"} />
            Matches
          </Menu.Item>
          <Menu.Item
            name="leaderboard"
            active={location.pathname === "/leaderboard"}
            onClick={() => window.location.replace("/leaderboard")}
          >
            <Icon name={"trophy"} />
            Leaderboard
          </Menu.Item>
          <Menu.Item
            name="active_players"
            active={false}
            onClick={() => window.location.replace("https://oczki.pl/s2-players")}
          >
            <Icon name={"user"} />
            Active Players
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Label
                pointing={"right"}
                color={"blue"}
                size={"large"}
                style={{ marginRight: "20px" }}
              >
                <Icon name={"gamepad"} />
                Change playlist
              </Label>
              <PlaylistCodePicker
                playlistCode={playlistCode}
                setPlaylistCode={setPlaylistCode}
                playlists={playlists}
              />
              <Label
                pointing={"right"}
                color={"blue"}
                size={"large"}
                style={{ marginRight: "20px" }}
              >
                <Icon name={"user"} />
                Find a player
              </Label>
              <PlayerSearchInput
                onResultSelect={(playfabId) => {
                  navigatePreservingSearchParams(`/stats/${playfabId}`)
                }}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Container fluid style={{ padding: "50px", marginTop: "50px" }} textAlign={"center"}>
          <Routes>
            <Route
              path={"/"}
              key={"tiers"}
              element={
                <TierDistributionPage
                  addUserToCache={addUserToCache}
                  userCache={userCache}
                  playlistCode={playlistCode}
                />
              }
            />
            <Route
              path={"/ranks"}
              key={"ranks"}
              element={
                <RankDistributionPage
                  getUser={addUserToCache}
                  userCache={userCache}
                  playlistCode={playlistCode}
                />
              }
            />
            <Route
              path={"/tier_changes"}
              key={"tierChanges"}
              element={<TierChangesPage playlistCode={playlistCode} />}
            />
            <Route
              path={"/stats/:playfabId"}
              key={"userStats"}
              element={
                <PlayerStatsModal
                  addUserToCache={addUserToCache}
                  userCache={userCache}
                  playlists={playlists}
                  playlistCode={playlistCode}
                  setPlaylistCode={setPlaylistCode}
                />
              }
            />
            <Route path={"/matches"} key={"matches"} element={<GathersPage />} />
          </Routes>
          {/*<WeaponStatsContextProvider>*/}
          {/*  <Routes>*/}
          {/*    <Route path={"/weapons"} key={"weapons"} element={<WeaponsGraph />} />*/}
          {/*  </Routes>*/}
          {/*</WeaponStatsContextProvider>*/}
        </Container>
      </div>
    </GlobalApiDataContextProvider>
  )
}

export default App
