import {
  ResolvedFinishedCaptureTheFlagRound,
  ResolvedFinishedDeathmatchRound,
  ResolvedFinishedDominationRound,
  ResolvedFinishedFreeForAllRound,
  ResolvedFinishedRound,
  ResolvedFinishedTeamDeathmatchRound,
  ResolvedFinishedTeamRound,
} from "./round"
import {
  FinishedCaptureTheFlagGame,
  FinishedDeathmatchGame,
  FinishedDominationGame,
  FinishedFreeForAllGame,
  FinishedGame,
  FinishedTeamDeathmatchGame,
  FinishedTeamGame,
} from "../finished/game"
import { GameModeName } from "../gameMode"
import { WithPlayerKillsAndDeaths } from "../player"
import { PlayerRating } from "../../ratings/player"

export type OmitPlayers<T> = Omit<T, "players">
export type OmitRounds<T> = Omit<T, "rounds">

export interface PlayerWithKillsAndRating {
  displayName: string
  playfabId: string
  team: string

  kills: number
  deaths: number
  kd: number

  oldRating: PlayerRating
  newRating: PlayerRating
}

export type ResolveFinishedGame<
  G extends FinishedGame,
  R extends ResolvedFinishedRound
> = WithPlayerKillsAndDeaths<
  OmitRounds<OmitPlayers<G>> & {
    players: PlayerWithKillsAndRating[]
    rounds: R[]
  } & {
    gameNumberInPlaylist: number
  }
>
export type ResolvedFinishedGame = ResolveFinishedGame<FinishedGame, ResolvedFinishedRound>
export type ResolvedFinishedTeamGame = ResolveFinishedGame<
  FinishedTeamGame,
  ResolvedFinishedTeamRound
>
export type ResolvedFinishedFreeForAllGame = ResolveFinishedGame<
  FinishedFreeForAllGame,
  ResolvedFinishedFreeForAllRound
>
export type ResolvedFinishedCaptureTheFlagGame = ResolveFinishedGame<
  FinishedCaptureTheFlagGame,
  ResolvedFinishedCaptureTheFlagRound
>
export type ResolvedFinishedTeamDeathmatchGame = ResolveFinishedGame<
  FinishedTeamDeathmatchGame,
  ResolvedFinishedTeamDeathmatchRound
>
export type ResolvedFinishedDominationGame = ResolveFinishedGame<
  FinishedDominationGame,
  ResolvedFinishedDominationRound
>
export type ResolvedFinishedDeathmatchGame = ResolveFinishedGame<
  FinishedDeathmatchGame,
  ResolvedFinishedDeathmatchRound
>

export function isResolvedFinishedTeamGame(
  finishedGame: ResolvedFinishedGame
): finishedGame is ResolvedFinishedTeamGame {
  return (
    finishedGame.gameMode === GameModeName.CaptureTheFlag ||
    finishedGame.gameMode === GameModeName.TeamDeathmatch ||
    finishedGame.gameMode === GameModeName.Domination
  )
}

export function isResolvedFinishedFreeForAllGame(
  finishedGame: ResolvedFinishedGame
): finishedGame is ResolvedFinishedFreeForAllGame {
  return finishedGame.gameMode === GameModeName.Deathmatch
}
