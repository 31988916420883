import React, { useEffect, useState } from "react"
import { GameModeSettings } from "soldat2-gatherbot-common/game/gamemode/settings"
import { Playlist } from "soldat2-gatherbot-common/game/playlist"
import { fetchPlaylists } from "../util/api"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

export const usePlaylists = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const playlistCode = searchParams.get("playlistCode")

  const [playlists, setPlaylists] = useState<Playlist<GameModeSettings>[]>([])

  useEffect(() => {
    fetchPlaylists().then((playlists) => {
      setPlaylists(playlists)
    })
  }, [])

  const setPlaylistCode = (playlistCode: string) => {
    setSearchParams((oldSearchParams) => {
      return { ...oldSearchParams, playlistCode }
    })
  }

  return { playlists, playlistCode, setPlaylistCode }
}
