import React, { useEffect, useState } from "react"
import { fetchTiers } from "../util/api"
import { TierResponse } from "soldat2-gatherbot-common/api/ratings"

export const TiersContext = React.createContext<{
  tiers: TierResponse[]
  setTiers: (tiers: TierResponse[]) => void
}>({
  tiers: [],
  setTiers: (tiers: TierResponse[]) => {},
})

export const TiersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [tiers, setTiers] = useState<TierResponse[]>([])

  useEffect(() => {
    fetchTiers().then((tiers) => {
      setTiers(tiers)
    })
  }, [])

  return <TiersContext.Provider value={{ tiers, setTiers }}>{children}</TiersContext.Provider>
}
