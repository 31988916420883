import React, { useContext, useState } from "react"
import { fetchGame } from "../util/api"
import { ResolvedFinishedGame } from "soldat2-gatherbot-common/game/resolved/game"

export interface GameCache {
  [startTime: string]: ResolvedFinishedGame
}

export const GameCacheContext = React.createContext<{
  gameCache: GameCache
  setGameCache: (newCache: GameCache) => void
}>({
  gameCache: {},
  setGameCache: (newCache: GameCache) => {},
})

export const GameCacheContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [gameCache, setGameCache] = useState<GameCache>({})

  return (
    <GameCacheContext.Provider value={{ gameCache, setGameCache }}>
      {children}
    </GameCacheContext.Provider>
  )
}

export const useGameCache = () => {
  const { gameCache, setGameCache } = useContext(GameCacheContext)

  const getGame = async (startTime: number) => {
    if (!(startTime in gameCache)) {
      const game = await fetchGame(startTime)

      console.log(`Fetched game ${game.startTime}`)

      const newCache = { ...gameCache, [startTime]: game }

      setGameCache(newCache)

      return game
    } else {
      return gameCache[startTime]
    }
  }

  return { getGame }
}
