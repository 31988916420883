import React, { useContext } from "react"
import { RankDistributionGraph } from "../graphs/RankDistributionGraph"
import { Header } from "semantic-ui-react"
import { RatingsContext } from "../context/ratings"
import { AddUserToCache, UserCache } from "../hooks/useUserCache"

export const RankDistributionPage = ({
  getUser,
  userCache,
  playlistCode,
}: {
  getUser: AddUserToCache
  userCache: UserCache
  playlistCode: string
}) => {
  const { ratings } = useContext(RatingsContext)

  return (
    <div>
      <Header as={"h2"} color={"blue"}>
        Rank Distribution
      </Header>

      <p>
        Hover over points to see the range of uncertainty in a player's skill. Click a point to view
        stats.
      </p>

      <RankDistributionGraph
        ratings={ratings}
        playlistCode={playlistCode}
        addUserToCache={getUser}
        userCache={userCache}
      />
    </div>
  )
}
