import { TierDistributionGraph } from "../graphs/TierDistributionGraph"
import React, { useContext } from "react"
import { Header } from "semantic-ui-react"
import { RatingsContext } from "../context/ratings"
import { TiersContext } from "../context/tiers"
import { AddUserToCache, UserCache } from "../hooks/useUserCache"

export const TierDistributionPage = ({
  addUserToCache,
  userCache,
  playlistCode,
}: {
  addUserToCache: AddUserToCache
  userCache: UserCache
  playlistCode: string
}) => {
  const { ratings } = useContext(RatingsContext)
  const { tiers } = useContext(TiersContext)

  return (
    <div>
      <Header as={"h2"} color={"blue"}>
        Tier Distribution
      </Header>

      <p>Hover over boxes to view individual players. Click to view their stats.</p>

      <TierDistributionGraph
        ratings={ratings}
        tiers={tiers}
        playlistCode={playlistCode}
        addUserToCache={addUserToCache}
        userCache={userCache}
      />
    </div>
  )
}
