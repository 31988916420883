import { MatchResult, MatchResultForPlayer } from "soldat2-gatherbot-common/game/matchResult"
import { SoldatTeam } from "soldat2-gatherbot-common/game/soldatTeam"

export const rgbRed = (alpha: number) => `rgb(231, 29, 54, ${alpha})`
export const rgbBlue = (alpha: number) => `rgb(0, 141, 248, ${alpha})`

export const rgbGreen = (alpha: number) => `rgb(73, 212, 87, ${alpha})`

export const rgbGray = (alpha: number) => `rgb(98, 112, 106, ${alpha})`

export const getColorForMatchResult = (result: MatchResult, alpha: number) => {
  let color

  if (result.winner === SoldatTeam.BLUE) {
    color = rgbBlue(alpha)
  } else if (result.winner === SoldatTeam.RED) {
    color = rgbRed(alpha)
  } else if (result.isTie) {
    color = rgbGray(alpha)
  } else {
    throw new Error(`Unexpected team result: ${result.winner}`)
  }

  return color
}

export const getColorForPlayerMatchResult = (result: MatchResultForPlayer, alpha: number) => {
  let color

  if (result === MatchResultForPlayer.VICTORY) {
    color = rgbGreen(alpha)
  } else if (result === MatchResultForPlayer.LOSS) {
    color = rgbRed(alpha)
  } else if (result === MatchResultForPlayer.TIE) {
    color = rgbGray(alpha)
  } else {
    throw new Error(`Unexpected player match result: ${result}`)
  }

  return color
}
