import { Card, Image, List, Loader } from "semantic-ui-react"
import moment from "moment"
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer"
import React, { useState } from "react"
import _ from "lodash"
import "./RatingCard.css"
import Input from "semantic-ui-react/dist/commonjs/elements/Input"
import { FullUserResponse } from "soldat2-gatherbot-common/api/ratings"
import { getRankIconUrl } from "../util/ranks"
import { UserCache } from "../hooks/useUserCache"
import { NumericInput } from "./NumericInput"
import { GamePopupForPlayer } from "./game_details/base"

interface Props {
  user: FullUserResponse | undefined
  interactive: boolean
  numLastGames: number
  setNumLastGames: (numLastGames: number) => void
}

export const RatingCard = ({ user, interactive, numLastGames, setNumLastGames }: Props) => {
  if (user === undefined) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading...</Loader>
      </Dimmer>
    )
  }

  const latestGames = _.sortBy(user.sortedGames, (game) => -game.startTime)

  return (
    <div className={"rating-card"} style={{ margin: "5px", height: "100%" }}>
      <Card style={{ height: "100%" }} fluid>
        <Card.Content>
          <Card.Header>
            {/*TODO: Change to user's avatar*/}
            <Image src={getRankIconUrl(user.playerStats.rating.tier)} wrapped ui={false} avatar />
            {user.displayName}
          </Card.Header>
          <Card.Meta>
            <span className="date">
              First Gather: {moment(user.playerStats.firstGameTimestamp).format("DD-MM-YYYY")}
            </span>
          </Card.Meta>
          <Card.Content>
            <br />
            <p>
              <b>Stats</b>
            </p>
            <List className={"stats-list"}>
              <List.Item>
                <List.Icon name={"star"} fitted />
                <List.Content>
                  <b>Rank</b>: {user.playerStats.rating.formattedTier}{" "}
                  <Image
                    src={getRankIconUrl(user.playerStats.rating.tier)}
                    style={{ height: 15 }}
                  />
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name={"gamepad"} fitted />
                <List.Content>
                  <b>Games Played</b>: {user.playerStats.totalGames}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name={"gamepad"} fitted />
                <List.Content>
                  <b>Rounds Played</b>: {user.playerStats.totalRounds}
                </List.Content>
              </List.Item>
              {/*TODO: Implement stats for each playlist */}
              {/*<List.Item>*/}
              {/*  <List.Icon name={"trophy"} fitted />*/}
              {/*  <List.Content>*/}
              {/*    <b>CTF W-T-L</b>: {user.playerStats.gameModeStats.CaptureTheFlag.wonGames}-*/}
              {/*    {user.playerStats.gameModeStats.CaptureTheFlag.tiedGames}-*/}
              {/*    {user.playerStats.gameModeStats.CaptureTheFlag.lostGames}*/}
              {/*  </List.Content>*/}
              {/*</List.Item>*/}
              {/*<List.Item>*/}
              {/*  <List.Icon name={"trophy"} fitted />*/}
              {/*  <List.Content>*/}
              {/*    <b>DOM W-T-L</b>: {user.playerStats.gameModeStats.Domination.wonGames}-*/}
              {/*    {user.playerStats.gameModeStats.Domination.tiedGames}-*/}
              {/*    {user.playerStats.gameModeStats.Domination.lostGames}*/}
              {/*  </List.Content>*/}
              {/*</List.Item>*/}
              <List.Item>
                <List.Icon name={"crosshairs"} fitted />
                <List.Content>
                  <b>Kills/Deaths</b>: {user.playerStats.totalKills}/{user.playerStats.totalDeaths}{" "}
                  ({(user.playerStats.totalKills / user.playerStats.totalDeaths).toFixed(2)})
                </List.Content>
              </List.Item>
            </List>
            {interactive ? (
              <b>
                Last{" "}
                <NumericInput
                  value={numLastGames}
                  onSubmit={(newValue) => setNumLastGames(newValue)}
                  width={70}
                />{" "}
                Games
              </b>
            ) : numLastGames !== 0 ? (
              <b>Last Games</b>
            ) : null}
            <List className={"games-list"} divided>
              {_.take(latestGames, numLastGames).map((game) => {
                return (
                  <GamePopupForPlayer key={game.startTime} game={game} interactive={interactive} />
                )
              })}
            </List>
          </Card.Content>
        </Card.Content>
        {/*<Card.Content extra>*/}
        {/*    <a>*/}
        {/*        <Icon name='user'/>*/}
        {/*        22 Friends*/}
        {/*    </a>*/}
        {/*</Card.Content>*/}
      </Card>
    </div>
  )
}
