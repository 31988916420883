import React, { useContext, useEffect, useRef, useState } from "react"
import { TierChangesGraph } from "../graphs/TierChangesGraph"
import { Button, Dimmer, Form, Grid, Header, Segment } from "semantic-ui-react"
import { PlayerSearchInput } from "../components/PlayerSearchInput"
import _ from "lodash"
import { fetchAllResolvedGamesForPlayer } from "../util/api"
import { TiersContext } from "../context/tiers"
import { RatingsContext } from "../context/ratings"
import { ResolvedFinishedGameForPlayer } from "soldat2-gatherbot-common/game/resolved/gameForPlayer"
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader"
import { NumericInput } from "../components/NumericInput"
import { useLocation, useSearchParams } from "react-router-dom"
import { useNavigatePreservingSearchParams } from "../hooks/useNavigatePreservingSearchParams"

export const TierChangesPage = ({ playlistCode }: { playlistCode: string }) => {
  const { tiers } = useContext(TiersContext)
  const { ratings, fetchingRatings } = useContext(RatingsContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const { navigatePreservingSearchParams } = useNavigatePreservingSearchParams()

  const [resolvedGamesPerPlayer, setResolvedGamesPerPlayer] = useState<{
    [playfabId: string]: ResolvedFinishedGameForPlayer[]
  }>({})

  const [fetchingResolvedGames, setFetchingResolvedGames] = useState<boolean>(false)

  const displayNames = _.fromPairs(ratings.map((rating) => [rating.playfabId, rating.displayName]))

  const [xAxisType, setXAxisType] = useState<"matches" | "time">("matches")
  const [numGamesToDisplay, setNumGamesToDisplay] = useState(100)

  const [playfabIds, setPlayfabIds] = useState<string[]>(searchParams.getAll("playfabId"))

  const previousPlayfabIds = useRef<string[]>([])
  const previousNumGamesToDisplay = useRef<number>(100)

  const addPlayer = (playfabId: string) => {
    if (!_.includes(playfabIds, playfabId)) {
      previousPlayfabIds.current = playfabIds
      const newPlayfabIds = [...playfabIds, playfabId]
      setPlayfabIds(newPlayfabIds)
      navigatePreservingSearchParams(location.pathname, undefined, {
        playfabId: newPlayfabIds,
      })
    }
  }

  const removePlayer = (playfabId: string) => {
    const newPlayfabIds = _.filter(playfabIds, (id) => id !== playfabId)
    const newResolvedGamesPerPlayer = _.clone(resolvedGamesPerPlayer)
    delete newResolvedGamesPerPlayer[playfabId]
    previousPlayfabIds.current = playfabIds
    setPlayfabIds(newPlayfabIds)
    setResolvedGamesPerPlayer(newResolvedGamesPerPlayer)
    navigatePreservingSearchParams(location.pathname, undefined, {
      playfabId: newPlayfabIds,
    })
  }

  const changeNumGamesToDisplay = (newNumGamesToDisplay: number) => {
    previousNumGamesToDisplay.current = numGamesToDisplay
    setNumGamesToDisplay(newNumGamesToDisplay)
  }

  useEffect(() => {
    if (
      playfabIds.length > previousPlayfabIds.current.length ||
      numGamesToDisplay !== previousNumGamesToDisplay.current
    ) {
      setFetchingResolvedGames(true)

      Promise.all(
        playfabIds.map(async (playfabId) => {
          return {
            playfabId,
            response: await fetchAllResolvedGamesForPlayer(
              playfabId,
              playlistCode,
              numGamesToDisplay
            ),
          }
        })
      ).then((results) => {
        const newResolvedGamesPerPlayer = _.clone(resolvedGamesPerPlayer)
        for (const { playfabId, response } of results) {
          newResolvedGamesPerPlayer[playfabId] = response.games
        }
        setResolvedGamesPerPlayer(newResolvedGamesPerPlayer)
        setFetchingResolvedGames(false)
      })
    }
  }, [numGamesToDisplay, playfabIds])

  return (
    <div>
      <Header as={"h2"} color={"blue"}>
        Rank Changes
      </Header>

      <p>
        Choose a list of players to compare their changes in rank. Click and drag to zoom in on
        portions of the graph. Double-click to zoom out fully.
      </p>

      <Grid>
        <Grid.Row centered>
          <Form>
            <Form.Group inline>
              <label>X-Axis</label>
              <Form.Radio
                inline
                label={"Time"}
                value={"time"}
                checked={xAxisType === "time"}
                onChange={(e, { value }) => setXAxisType(value as "matches" | "time")}
              />
              <Form.Radio
                inline
                label={"Match Number"}
                value={"matches"}
                checked={xAxisType === "matches"}
                onChange={(e, { value }) => setXAxisType(value as "matches" | "time")}
              />

              <label>Matches To Display</label>
              <span>
                Last{" "}
                <NumericInput
                  value={numGamesToDisplay}
                  onSubmit={(newValue) => changeNumGamesToDisplay(Math.max(1, newValue))}
                  width={70}
                />{" "}
                Matches
              </span>
            </Form.Group>
            <Form.Group inline>
              <label>Add players</label>
              <div style={{ marginRight: "5px" }}>
                {fetchingRatings ? (
                  <Loader inline active size={"tiny"} />
                ) : (
                  <PlayerSearchInput
                    onResultSelect={(playfabId) => {
                      addPlayer(playfabId)
                    }}
                  />
                )}
              </div>
              {playfabIds.map((playfabId) => {
                const displayName = displayNames[playfabId]
                return (
                  <Button
                    key={playfabId}
                    // Do not listen to enter key events
                    type="button"
                    color={"blue"}
                    onClick={(event) => {
                      removePlayer(playfabId)
                    }}
                  >
                    {displayName}
                  </Button>
                )
              })}
            </Form.Group>
          </Form>
        </Grid.Row>
      </Grid>

      <Dimmer.Dimmable dimmed={fetchingResolvedGames} as={Segment}>
        <TierChangesGraph
          gamesPerPlayer={resolvedGamesPerPlayer}
          xAxisType={xAxisType}
          numGamesToDisplay={numGamesToDisplay}
          showLegend={true}
          figureHeight={400}
          getGameNumber={(game) => game.gameNumberInPlaylist}
        />

        <Dimmer active={fetchingResolvedGames} inverted>
          <Loader active>Loading rank changes...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  )
}
